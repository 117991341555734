.voice_exercise_button_container {
  display: flex;
  justify-content: center;
  margin-top: -4px;
  user-select: none; /* Для современных браузеров */
    -webkit-user-select: none; /* Для Safari */
}

.voice_exercise_button {
  display: flex;
  width: 64%;
  min-width: 195px;
  height: 65px;
  background-color: #a180c8;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 34px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

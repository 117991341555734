/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../../../public/fonts/montserrat-v26-latin-regular.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 800;
  src: url("../../../public/fonts/montserrat-v26-latin-800italic.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* inter-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("../../../public/fonts/inter-v13-latin-regular.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("../../../public/fonts/inter-v13-latin-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

.search_block {
  margin-top: 48px;
  text-align: center;
  width: 53vw;
  max-width: 576px;
}
.search_bar {
  color: rgb(34, 34, 34);
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0%;

  width: 100%;
  height: 56px;
  border: 0;
  padding: 10px 40px 10px 50px;
  background-image: url("../../../public/images/search-icon.svg");
  background-size: 20px;
  background-position: left 10px center;
  background-repeat: no-repeat;
  border-radius: 32px;
  background-color: rgb(246, 246, 246);
}
.search_bar:focus {
  outline: none;
}
.search_bar::-webkit-search-cancel-button {
  display: none;
}

@media (max-width:565px){
  .search_bar{
    width:60vw;
  }
}
/* Loader.css */
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный фон */
    z-index: 1000; /* Обеспечивает, что лоадер будет поверх всего */
  }
  
  .loader {
    border: 16px solid #f3f3f3; /* Светлый серый */
    border-top: 16px solid rgb(161, 128, 200); /* Синий */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
.not_active_subscription_wrapper {
  margin-left: 18.5%;
  margin-top: 50px;
}

.not_active_subscription_container {
  width: 26.5%;
  height: 410px;
  background-color: #a180c8;
  display: flex;
  flex-direction: column;
  border-radius: 34px;
  min-width: 250px;
  max-width: 500px;
}

.not_active_subscription_plan {
  padding: 32px 32px 16px 32px;
  font-family: Montserrat;
  font-size: 2.6vh;
  font-weight: 600;
  line-height: 35px;
  text-align: left;
}

@media (max-width: 1900px) {
  .not_active_subscription_plan {
    font-size: 25px;
  }
}

@media (min-width: 1900px) {
  .not_active_subscription_plan {
    font-size: 25px;
  }
}

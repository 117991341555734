.container {
    background-color: #b292d4;
    padding: 20px;
    border-radius: 20px;
    width: max-content;
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 20px; 
    margin-left: -25px;
    z-index: 1000; 
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.container.show {
    opacity: 1;
    transform: translateY(0);
}

.container::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 20px; 
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #b292d4;
}

.top-rounded {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #b292d4;
    padding: 10px;
}

.button {
    background-color: white;
    border-radius: 20px;
    padding: 10px 20px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    color: #b292d4;
    font-family: Arial, sans-serif;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.button {
    background-color: #f2f2f2;
}

.arrow {
    margin-left: 10px;
}
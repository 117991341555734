.generate_topic_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 14%;
}



.generate_search_bar {
  display: flex;

  align-items: end;
  justify-content: center;
}

.generate_topic_button {
  display: flex;
  width: 219px;
  height: 56px;
  border-radius: 32px;
  cursor: pointer;
  border: none;
  background-color: #a180c8;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: center;
  color: #fff;
  align-items: center;
  justify-content: center;
  margin-left:10px ;
}

.generate_topic_button:hover{
  background-color: #896daa;
  transition: all 0.3s ease 0s;
}

@media (max-width:565px){
  .generate_topic_button{
    width: 80px;
    font-size: 12px;
    margin-left:105px;
  }
  .generate_icon{
    width: 30px;
    height: 30px;
  }
  .generate{
    display: none;
  }
  
}
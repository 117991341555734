.topics-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}



.popularTopicElement {
  
  background: linear-gradient(180deg, #A180C8 0%, #C3B9CE 100%);
min-width: 211px;
}
.topic_name{
  font-size: 2.8vh;
  line-height: 1.5;
  
}





@media (min-width: 1900px) {
  .topic_name {
    font-size: 26px;
  }
}

@media (max-width: 1220px) {
  .topic_name {
    font-size: 20px;
  }
}


.menu_wrapper {
  width: 100%;

  margin-left:25px
}

.slick-track {
  height: 225px;
  display: flex;
  justify-content: flex-start;
  margin: 0;
}

.slick-slider .slick-arrow {
  display: none;
}

.slick-slider .slick-prev,
.slick-slider .slick-next {
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
}

.slick-slider .slick-prev {
  background-image: url("../../../public/images/prev-arrow.svg");
  background-position: center;
  left: -50px;
}

.slick-slider .slick-next {
  background-image: url("../../../public/images/next-arrow.svg");
  background-position: center;
}

.slick-prev:before {
  content: none;
}
.slick-next:before {
  content: none;
}

.desc {
  overflow: hidden;
  padding: 0 20px;
}
.topic_name {
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-weight: 500;
  line-height: 49px;
  letter-spacing: 0px;
  text-align: left;
}
.topic_text {
  color: rgb(255, 255, 255);
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0%;
  text-align: left;
}

.background_img{
  background: linear-gradient(180deg, #A180C8 0%, #C3B9CE 100%);

}

.slick-slide { 
  min-width: 211px; 
}
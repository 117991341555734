.trial_wrapper {
  margin-left: 18.5%;
  margin-top: 24px;
}

.subscription_spoiler_container {
  width: 26.5%;
  height: 118px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #dadada;
  border-radius: 34px;
  gap: 16px;
  text-wrap: nowrap;
  margin-bottom: 36px;
  min-width: 250px;
  max-width: 500px;
}

.subscription_spoiler_text {
  padding-left: 24px;
  font-family: Montserrat;
  font-size: 2vh;
  font-weight: 500;
  line-height: 26.6px;
  text-align: left;
}

.subscription_spoiler_span {
  color: #a180c8;
}

.subscription_spoiler_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
}

.not_active_subscription_free {
  color: #a180c8;
  font-family: Montserrat;
  font-size: 1.7vh;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
}

.not_active_subscription_date {
  font-family: Montserrat;
  font-size: 1.7vh;
  font-weight: 500;
  line-height: 21px;
  margin-left: 20px;
  color: #a180c8;
}

@media (max-width: 1900px) {
  .not_active_subscription_date,
  .not_active_subscription_free {
    font-size: 15px;
  }
  .subscription_spoiler_text {
    font-size: 15px;
  }
}

@media (min-width: 1900px) {
  .not_active_subscription_date,
  .not_active_subscription_free {
    font-size: 15px;
  }
  .subscription_spoiler_text {
    font-size: 19px;
  }
}

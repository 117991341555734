.exercise_word_flexible_wrapper {
  display: flex;
  justify-content: center;
}

.drag_and_drop_exercise_word {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  min-width: 101px;
  min-height: 79px;
  border-radius: 34px;
  background-color: #efe1ff;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  line-height: 30.8px;
  text-align: right;
}

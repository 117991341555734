.continue_button {
  color: white;
  width: 10vw;
  height: 56px;
  font-family: Inter;
  font-size: 0.95vw;
  font-weight: 500;
  line-height: 25.2px;
  text-align: center;
  border: none;
  border-radius: 32px;
  cursor: pointer;
  text-align: center;
  margin-left: 145px;
}

@media (max-width:565px){
  .continue_button{
  width: 75px;
  height: 40px;
  font-size: 13px;
  }
  
}

.login_wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.login_description {
  font-family: Montserrat;
  font-size: 4vh;
  font-weight: 600;
  line-height: 6vh;
  text-align: center;
  width: 30vw;
}
.input_name {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin: 20px 0px 6px 14px;
  color: #344054;
}
.password_recovery {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  text-decoration: none;

  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.password_recovery,
a:visited,
a:focus {
  color: #6941c6;
}

.password_recovery:hover {
  color: #5937a8;
  transition: 0.3s;
}

.login_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.google_button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.google_button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d0d5dd;
  width: 19vw;
  max-width: 360px;
  min-width: 187px;
  height: 45px;
  border-radius: 32px;
  cursor: pointer;
  background-color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

.google_button:hover{
  background-color: #F1E5FF;
  transition:0.3s;
}

.google_icon {
  margin-right: 12px;
}

.registration_offer {
  display: flex;
  justify-content: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.registration_offer_link {
  color: #6941c6;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-decoration: none;
}

.registration_offer_link,
a:visited,
a:focus {
  color: #6941c6;
}

.registration_offer_link:hover {
  color: #5937a8;
  transition: 0.3s;
}

.listen_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
}

.listen_button {
  display: flex;
  width: 16%;
  min-width: 156px;

  border: 1px solid #e4e7ec;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
  cursor: pointer;
}

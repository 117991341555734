.subscription_screen {
    margin-left: 18.5%;
    margin-top: 62px;
    font-family: Montserrat;
    font-size: 45px;
    font-weight: 600;
    line-height: 63px;
    text-align: left;
}

@media (max-width:565px){
    .subscription_screen{
        display: flex;
        justify-content: center;
        margin-left: 0;
    }
}
.PaymentPagePopUp_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c1c1c154;
  z-index: 1;
}

@supports (backdrop-filter: blur(4px)) {
  .PaymentPagePopUp_wrapper {
    backdrop-filter: blur(4px);
  }
}

@media not all and (backdrop-filter: blur(4px)) {
  .PaymentPagePopUp_wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #c1c1c1;
    opacity: 0.5;

    z-index: 1;
  }
}

.PaymentPagePopUp_container {
  width: 20%;
  height: 370px;
  background-color: #fff;
  padding: 40px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  border-radius: 34px;
  text-align: center;
  align-items: center;
}

.subscribe_popUp_title {
  font-family: Inter;
  font-size: 1.5vw;

  font-weight: bold;
  
  text-align: center;

}
.subscribe_popUp_icon {
  
  height: 65px;
  width: 65px;
  margin-top: 28px;
  margin-bottom: 53px;
}

.popUp_close_button_wrapper {
  
  margin-left: 70%;
}

.support{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  color: #737373;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0;
  text-align: center;
}

@media (max-width:565px){
  .PaymentPagePopUp_container{
    width:260px;
    height: 350px;
    
  }
  .subscribe_popUp_title{
    font-size: 3.5vw;
  }
}
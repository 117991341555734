.container2 {
    display: flex;
    align-items: center;
    min-width: 170px;
    height: 44px;
    width: 18vw;
    margin-top: 44px;
    border-radius: 8px;
    border: 1px solid transparent;
    position:relative;
    background-color: #F05252;
}

.info{
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 12px;
    position: relative;
    font-family: Montserrat;
    /* text-align: center; */
    color: #FFFFFF;
}
.close_button {
  width: 55px;
  height: 55px;
  background-image: url("../../../public/images/close-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  background-color: white;
  margin-left: 100%;
  margin-top: 2%;
  position: relative;
}

@media (max-width:565px){
  .close_button{
    margin-left :90%;
   }
}
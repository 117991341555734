.drag_and_drop_exercise_words_area_wrapper {
  display: flex;
  justify-content: center;
}

.drag_and_drop_exercise_words_area {
  display: flex;
  margin-top: -4.5px;
  border-radius: 34px;
  width: 630px;
  min-height: 95px;
  border: 1px solid #d2d2d2;
  margin-bottom: 12.5px;
  flex-wrap: wrap;
}

.payment_page_button_wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.payment_page_button {
  border: none;
  width: 19vw;
  min-width: 187px;
  max-width: 360px;
  height: 45px;
  border-radius: 32px;
  cursor: pointer;
  margin-top: 24px;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 22.4px;
  text-align: center;
}

.paymentButton_error_message{
  margin-top: 5px;
  font-family: Montserrat;
font-size: 15px;
font-weight: 400;
line-height: 24px;
text-align: left;
color:#F64C4C;
}
/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: "../../../public/fonts/montserrat-v26-latin-regular.woff2"
    format("woff2");
}
/* montserrat-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 800;
  src: url("../../../public/fonts/montserrat-v26-latin-800italic.woff2")
    format("woff2");
}

@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../../../public/fonts/montserrat-v26-latin-500.woff2")
    format("woff2");
}

/* inter-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("../../../public/fonts/inter-v13-latin-regular.woff2")
    format("woff2");
}
/* inter-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("../../../public/fonts/inter-v13-latin-600.woff2") format("woff2");
}

.dictionarySearchBoxWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dictionaryName {
  color: rgb(34, 34, 34);
  font-family: "Montserrat";
  font-size: 35px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 2px;
}

.dictionarySearchBoxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
}

.dictionaryBackButton {
  order: -1;
}

.dictionarySearchBlock {
  flex: 1;
  text-align: center;
}

.dictionarySearchBox {
  color: rgb(34, 34, 34);
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0%;
  width: 38%;
  height: 56px;
  border: 0;
  padding: 10px 40px 10px 50px;
  background-image: url("../../../public/images/search-icon.svg");
  background-size: 20px;
  background-position: left 10px center;
  background-repeat: no-repeat;
  border-radius: 32px;
  background-color: rgb(246, 246, 246);
}

input:active,
input:hover,
input:focus {
  outline: 0;
  outline-offset: 0;
}

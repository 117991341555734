/* montserrat-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: url("../../../public/fonts/montserrat-v26-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* montserrat-800italic - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 800;
    src: url("../../../public/fonts/montserrat-v26-latin-800italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  
  /* inter-regular - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    src: url("../../../public/fonts/inter-v13-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* inter-600 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    src: url("../../../public/fonts/inter-v13-latin-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  .search_wrapper {
    display: block;
  }
  .search_topic {
    color: rgb(30, 30, 30);
    font-family: Montserrat;
    font-size: 76px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 100px;
  }
  
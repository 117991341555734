/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: "../../../public/fonts/montserrat-v26-latin-regular.woff2"
    format("woff2");
}
/* montserrat-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 800;
  src: url("../../../public/fonts/montserrat-v26-latin-800italic.woff2")
    format("woff2");
}

/* inter-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("../../../public/fonts/inter-v13-latin-regular.woff2")
    format("woff2");
}
/* inter-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("../../../public/fonts/inter-v13-latin-600.woff2") format("woff2");
}

.theme_wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme_name {
  color: rgb(255, 255, 255);
  margin: 0;
  font-family: "Inter";
  font-size: 70px;
  font-weight: 500;
  line-height: 85px;
  letter-spacing: 0px;
  text-align: right;
}
.theme_desc {
  color: rgb(255, 255, 255);
  margin-top: 12px;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0%;
  text-align: right;
}
.text_container {
  margin: 151px 48px 48px 48px;
}
.container_back_btn {
  text-align: left;
  margin: 48px 48px 48px;
}
.back_btn {
  background-image: url("../../../public/images/back_btn.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 50px;
  width: 68px;
  height: 68px;
  cursor: pointer;
}
.exercise_wrapper {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exercise_container {
  max-width: 1770px;
  min-height: 385px;
  border-radius: 34px;
  background: rgb(161, 128, 200);
  display: flex;
  flex-wrap: wrap;
}

.exercise_logo {
  text-align: left;
  margin: 30px 32px auto;
  color: rgb(255, 255, 255);
  font-family: "Inter";
  font-size: 35px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0px;
}

.cards_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media only screen and (max-width: 1200px) {
  .card1,
  .card2,
  .card3,
  .card4 {
    margin: 12.27px 10px 30px 10px;
  }
  .exercise_container {
    justify-content: center;
  }
}

.footer_success {
  display: flex;
  background-color: #d7f2cb;
  bottom: 0;
  position: fixed;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 9vw;
}

.footer_fail {
  display: flex;
  background-color: #f9edec;
  bottom: 0;
  position: fixed;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 9vw;
}

.footer_content {
  display: flex;
}
.footer_icon_success {
  align-items: center;
  width: 3.7vw;
  height: 3.7vw;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-image: url("./../../../public/images/success-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 2vw;
}

.footer_icon_fail {
  align-items: center;
  width: 3.7vw;
  height: 3.7vw;
  background-color: white;
  display: flex;
  justify-content: center;
  background: cover;
  align-items: center;
  border-radius: 50px;
  background-image: url("./../../../public/images/fail-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.footer_status_text_success {
  padding-left: 0.5vw;
  display: flex;
  align-items: center;
  color: #7bbd25;
  font-family: Inter;
  font-size: 0.95vw;
  font-weight: 500;
  line-height: 25.2px;
  text-align: center;
}

.footer_status_text_fail {
  padding-left: 0.5vw;
  display: flex;
  align-items: center;
  color: #d5362c;
  font-family: Inter;
  font-size: 0.95vw;
  font-weight: 500;
  line-height: 25.2px;
  text-align: center;
}

.button_container {
  display: flex;
  align-items: center;
}

.continue_button {
  background-color: #9fc66c;
}

.status_icon_container {
  display: flex;
  align-items: center;
}

@media (max-width:565px){
  .footer_success{
    height: 80px;
  }
  .footer_fail{
    height:80px;
  }
  .footer_status_text_success{
    font-size: 13px;
    line-height: 0;
  }
  .footer_status_text_fail{
    font-size: 13px;
    line-height:15px ;
    width: 150px;
  }
  .footer_icon_success{
    width: 25px;
    height: 25px;
  
  }
  .footer_icon_fail{
    width: 25px;
    height: 25px;
    
  }
}

.voice-recording {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    
  }
  
  .recording-indicator {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.5rem;
    color: #fff;
    width: 160px;
    font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: center;
  }
  
  .recording-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ff0000;
    animation: pulse 1s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    /* Контейнер занимает всю высоту экрана */
}

.support_footer {
    margin-top: auto;
    /* Футер будет "прилипать" к низу */
    text-align: center;
    padding: 20px;
}
  
  .support_footer p {
    margin: 0; /* Убираем отступы для лучшего выравнивания */
  }
  
  .support_footer a {
    color: #007bff; /* Цвет ссылки */
    text-decoration: none;
  }
  
  .support_footer a:hover {
    text-decoration: underline;
  }
  
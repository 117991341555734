/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: "../../../public/fonts/montserrat-v26-latin-regular.woff2"
    format("woff2");
}
/* montserrat-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 800;
  src: url("../../../public/fonts/montserrat-v26-latin-800italic.woff2")
    format("woff2");
}

/* inter-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("../../../public/fonts/inter-v13-latin-regular.woff2")
    format("woff2");
}
/* inter-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("../../../public/fonts/inter-v13-latin-600.woff2") format("woff2");
}

.header_container {
  max-width: 95%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px auto;
  
}

.logo,
.username {
  padding: 10px;
  color: rgb(34, 34, 34);
  font-size: 16px;
  font-weight: 400; 
  line-height: 140%;
  letter-spacing: 0;
  border-radius: 24px;
  border: 1px;
  /* text-align: center; */
}
.header-burger-menu{
  display: none;
}

.home,
.trophy {
  padding: 10px;
  cursor: pointer;
}
.home {
  color: rgb(34, 34, 34);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0;
  text-align: center;
}
.trophy {
  color: rgb(34, 34, 34);
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0%;
  text-align: center;
  background-image: url("../../../public/images/underline.svg");
  background-size: 100% 8.3px;
  background-position: 0 100%;
  background-repeat: no-repeat;
}

.logo {
  order: 1;
  display: flex;
  align-items: center;
  color: rgb(34, 34, 34);
  font-size: 32px;
  font-weight: 800;
  line-height: 29px;
  letter-spacing: 0;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  cursor: pointer;
}

.home-trophy {
  display: flex;
  flex-wrap: wrap;
  order: 2;
}

.username {
  order: 3;
  /* color: rgb(34, 34, 34);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0; */
  border-radius: 24px;
  /* text-align: center; */

}

.username, .buttons-details{
  display: flex;
  flex-direction: column;
  gap: 20px;
}


@media screen and (max-width: 565px) {
  .header_container {
    flex-direction: column;
    align-items: flex-start;
  }

  .logo,
  .home-trophy {
    width: 100%;
    text-align: center;
    
  }
.home-trophy{
  flex-wrap: nowrap;
  margin-top: 15px;
}
  .home,
  .trophy {
    width: 100%;
  }
  .logo{
    padding:0;
    display: flex;
  }
  .username{
    display: none;
  }
.logo-burger{
  display: flex;
  justify-content: space-between;
  width:100%
}

.header-burger-menu{
  display: flex;
  order:3;
}

.header-burger-checkbox {
  position: absolute;
  visibility: hidden;
}

.burger {
  position: relative;
  z-index: 1;
  cursor: pointer;
  display: block;
  border: none;
  background: transparent;
  width: 33px;
  height: 26px;
}

.burger::before,
.burger::after {
  content: '';
  left: 0;
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 10px;
  background: #000;
}

.burger::before {
  top: 0;
  box-shadow: 0 11px 0 #000;
  transition: box-shadow .3s .15s, top .3s .15s, transform .3s;
}

.burger::after {
  bottom: 0;
  transition: bottom .3s .15s, transform .3s;
}

.header-burger-checkbox:checked + .burger::before {
  top: 11px;
  transform: rotate(45deg);
  box-shadow: 0 6px 0 rgba(0,0,0,0);
  transition: box-shadow .15s, top .3s, transform .3s .15s;
}

.header-burger-checkbox:checked + .burger::after {
  bottom: 11px;
  transform: rotate(-45deg);
  transition: bottom .3s, transform .3s .15s;
}

.burger-menu-list {
  top: 0;
  right: 0; /* Меняем left на right */
  position: absolute;
  display: grid;
  gap: 12px;
  padding: 42px 0;
  margin: 0;
  background: #a180c8;
  
  list-style-type: none;
  transform: translateY(-100%); /* Меняем translateX на 100% для выхода за пределы экрана */
  transition: .3s;
  width: 200px;
  border-radius: 15px;
}

.burger-menu-item {
  display: block;
  padding: 8px;
  color: white;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  order: 3;
}

.burger-menu-item:hover {
  background: rgba(255, 255, 255, .2);
}

.header-burger-checkbox:checked ~ .burger-menu-list {
  transform: translateX(0); /* Эта строка остается без изменений, чтобы меню появлялось по нажатию */
}


}


/* montserrat-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../../../public/fonts/montserrat-v26-latin-regular.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* montserrat-800italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 800;
  src: url("../../../public/fonts/montserrat-v26-latin-800italic.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* inter-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("../../../public/fonts/inter-v13-latin-regular.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* inter-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("../../../public/fonts/inter-v13-latin-600.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

.tags_wrapper {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: rgb(80, 80, 80);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
}

.tags_list {
  display: flex;
  flex-wrap: wrap;
}

.tags_item {
  margin-right: 24px;
  list-style-type: none;

  width: 145px;
  height: 42px;
  background: rgb(246, 246, 246);
  border-radius: 32px;
}

.tags_link {
  text-decoration: none;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

a:visited {
  color: rgb(80, 80, 80);
}

a:active {
  color: rgb(80, 80, 80);
}

a {
  color: rgb(80, 80, 80);
}

@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../../../public/fonts/montserrat-v26-latin-500.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

.exercise_button_common_color {
  min-width: 8vw;

  height: 79px;
  cursor: pointer;
  border-radius: 34px;
  background-color: #efe1ff;
  font-family: Montserrat;
  font-size: 1.2vw;
  font-weight: 500;
  line-height: 30.8px;
  text-align: center;
  border: none;
  transition: 0.3s;
}

.exercise_button_success_color {
  min-width: 8vw;

  height: 79px;
  cursor: pointer;
  border-radius: 34px;
  background-color: #9fe08069;
  font-family: Montserrat;
  font-size: 1.2vw;
  font-weight: 500;
  line-height: 30.8px;
  text-align: center;
  border: none;
  transition: 0.3s;
  border: 1px solid #7bbd25;
}

.exercise_button_fail_color {
  min-width: 8vw;

  height: 79px;
  cursor: pointer;
  border-radius: 34px;
  background-color: #f9edec;
  font-family: Montserrat;
  font-size: 1.2vw;
  font-weight: 500;
  line-height: 30.8px;
  text-align: center;
  border: none;
  transition: 0.3s;
  border: 1px solid #d5362c;
}

@media (max-width:565px){
  .exercise_button_common_color{
    font-size: 1.5vh;
    width: 100px;
  }
  .exercise_button_success_color{
    font-size: 1.5vh;
    width: 100px;
  }
  .exercise_button_fail_color{
    font-size: 1.5vh;
    width: 100px;
  }
}
.generate_topic_box_wrapper{
    background: linear-gradient(180deg, #A180C8 0%, #C3B9CE 100%);

}

.generate_topic_box_container{
    font-size: 40px;
  line-height: 1.5;
}


  
 
  
  @media (max-width: 1220px) {
    .generate_topic_box_container {
      font-size: 30px;
    }
  }
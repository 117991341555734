.dictionaryItemContainer {
  display: flex;
  flex-direction: column;
  transition: 0.5s;
  margin: 30px auto;
  width: 90%;
  cursor: pointer;
}
.dictionaryItemContainer:hover {
  transform: translateY(-10px);
  transition: 0.5s;
}

.frontSide {
  background-color: rgb(246, 246, 246);
  border-radius: 34px;
  height: 176px;
}
.backSide {
  background-color: rgb(161, 128, 200);
  border-radius: 34px;

  height: 176px;
}

.dictionaryTitle {
  color: rgb(34, 34, 34);
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0px;
  text-align: left;
  margin: 34.5px auto 8.5px 32px;
}

.dictionaryDescription {
  margin: 8.5px auto 54px 32px;
  color: rgb(69, 69, 69);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0px;
  text-align: left;
}

.title_container {
  display: flex;
  align-items: center;
}
.dictionarySoundButton {
  margin: 34.5px auto 8.5px 24px;
  align-self: center;
}

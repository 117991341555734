.voice_exercise_button_container {
  display: flex;
  justify-content: center;
  margin-top: -4px;
}

.voice_exercise_button {
  display: flex;
  width: 20%;
  min-width: 195px;
  height: 65px;
  background-color: #a180c8;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 34px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: center;
  align-items: center;
  justify-content: center;

  position: relative; 
 
}

.voice_exercise_button .dots {
  position: absolute; 
  
  top: 50%;
  transform: translateY(-50%); 

  position: absolute;
}

.voice_exercise_button:hover {
  transform: scale(1.05);
  background-color: #8a66b3;

}

.dots::after {
  content: "";
  display: inline-block;
  animation: dotsAnimation 1.2s infinite steps(4, end);
}

@keyframes dotsAnimation {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: ".. ";
  }
  75%, 100% {
    content: "...";
  }
}
.dictionary_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.dictionary_item {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
}

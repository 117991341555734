.card {
  width: 390.44px;
  height: 260px;
  border-radius: 34px;
  margin: 12.27px 25px 30px 27px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card.enabled {
  background: rgb(233, 213, 255);
}

.card.disabled {
  background: rgba(255, 255, 255, 0.4);
}

.icon_container {
  display: flex;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 18px;
  background: rgb(243, 232, 255);
  border: none;
  cursor: pointer;
  margin: 24px 24.44px auto 310px;
}

.icon_container_disabled {
  background-color: rgba(243, 232, 255, 0.5);
}

.exercise_name {
  width: 211px;
  color: rgb(34, 34, 34);
  font-family: "Inter";
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  margin: 48px auto auto 24px;
}

.exercise_desc {
  width: 211px;
  color: rgb(34, 34, 34);
  font-family: "Inter";
  font-size: 15px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0px;
  text-align: left;
  margin: 6px auto 24px 24px;
}

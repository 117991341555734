.new_word_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 112px;
}
.new_word_logo {
  font-family: Montserrat;
  font-size: 2vw; /* Используем vw для относительного изменения размера шрифта */
  font-weight: 500;
  line-height: 1.4; /* Примерное соотношение линий для лучшего отображения */
  text-align: left;
  color: #a180c8;
  padding-left: 1.5vw; /* Используем vw для относительного отступа */
  font-weight: bold;
}
.new_word_translate{
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  font-family: "Montserrat";
  font-size: 2vw;
  font-weight: 600;
  line-height: 56px;
  text-align: left;
}

@media (max-width:565px){
  .new_word_translate{
    font-size: 20px;
  }
  .new_word_logo{
    font-size: 22px;
  }
}
.subscription_button_wrapper {
  margin-left: 18.5%;
  margin-top: 50px;
}

.subscription_button_container {
  display: flex;
  width: 26.5%;
  height: 410px;
  background-color: #a180c8;
  border-radius: 34px;
  flex-direction: column;
  min-width: 250px;
  max-width: 500px;
}

.subscription_plan {
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 600;
  line-height: 35px;
  text-align: left;
}

.subscription_status {
  margin-top: 32px;
  margin-left: 32px;
  display: flex;
  justify-content: space-between;
  width: 84%;
}

.subscription_date {
  display: flex;
  margin-top: 7px;
  justify-content: flex-end;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
  line-height: 18.2px;
  text-align: right;
  color: #636363;
  margin-right: 32px;
}

.subscription_price {
  margin-left: 32px;
  font-family: Montserrat;
  font-size: 3.6vh;
  font-weight: 600;
  line-height: 49px;
  text-align: left;
}

.subscription_info {
  margin-top: 8px;
  color: #303030;
  font-family: Montserrat;
  font-size: 1.6vh;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
}
ul.subscription_info > li {
  margin-top: 16px;
  list-style-type: none;
}

ul.subscription_info > li::before {
  content: url("../../../public/images/sub_list-icon.svg ");
  padding-right: 16px;
}

.subscriptions_payment_button {
  display: flex;
  justify-content: center;
}

@media (min-width: 1667px) {
  .subscription_price {
    font-size: 35px;
  }
  .subscription_plan {
    font-size: 25px;
  }
  .subscription_info {
    font-size: 15px;
  }
}

@media (max-width: 1667px) {
  .subscription_price {
    font-size: 30px;
  }

  .subscription_plan {
    font-size: 20px;
  }

  .subscription_info {
    font-size: 12px;
  }
}

.progress_bar_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 74px;
  border-radius: 5px;
}

.progress_wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  margin-right: 75px;
}

.progress {
  width: 25.5%;
  background-color: #e4e4e4;
  height: 12px;
  border-radius: 5px;
}

.progress_bar {
  background-color: #a180c8;
  text-align: center;
  line-height: 12px;
  height: 12px;
  border-radius: 5px;
}
.exit_button {
  margin-left: 75px;
}

@media (max-width:565px){
  .progress_bar_container{
    display: none;
  }
  
}
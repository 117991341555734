.registration_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}
.registration_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.registration_password_error{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}
.recaptcha{
  margin-top:20px;
}
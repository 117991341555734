.generate_description_wrapper {
    
    margin-top: 50px;
  }
  
  .generate_description_container {
    width: 25vw;
    height: 410px;
    background-color: #a180c8;
    display: flex;
    flex-direction: column;
    border-radius: 34px;
    min-width: 250px;
    max-width: 500px;
  }
  .generate_description_examples{
    display: flex;
    justify-content: center;
    margin-top: 15px;
    color: #303030;
    font-family: Montserrat;
    font-size: 1.5vw;
    font-weight: 500;
    line-height: 22.4px;
    
  }
  .generate_description_list {
    margin-top: 8px;
    color: #303030;
    font-family: Montserrat;
    font-size: 1vw;
    font-weight: 500;
    line-height: 22.4px;
    text-align: left;
  }
  ul.generate_description_list > li {
    margin-top: 16px;
    list-style-type: none;
  }
  
  ul.generate_description_list> li::before {
    content: url("../../../public/images/sub_list-icon.svg ");
    padding-right: 16px;
  }
 
  @media (max-width:565px){
    .generate_description_container{
      width: 310px;
  }
  .generate_description_examples{
    font-size: 15px;
  }
    .generate_description_list{
      font-size: 13px;
      width:265px;
      
    }
    
  }

  @media (min-width: 1667px) {
  .generate_description_examples{
font-size: 15px;
  }

    .generate_description_list {
      font-size: 13px;
    }
  }
  

  
 
  
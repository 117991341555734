.subscription_status_flag{
    display: flex;
    width: 115px;
    height: 35px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
}

.active{
    color: #55DD5A;
    background-color: #64C15C78;
}

.cancelled {
    color: #FFFFFF; /* Белый цвет для текста */
    background-color: rgba(128, 0, 0, 0.7); /* Темно-красный цвет для фона */
}

.active-image::before{
    content:url("../../../public/images/sub_status-icon.svg ");
    padding-right:10px ;
    padding-top: 4px;
}
.drag_and_drop_exercise_description {
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 600;
  line-height: 56px;
  text-align: center;
  user-select: none; /* Для современных браузеров */
    -webkit-user-select: none; /* Для Safari */
}

.drag_and_drop_exercise_words_container_wrapper {
  display: flex;
  justify-content: center;
}

.drag_and_drop_exercise_words_container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 630px;
  user-select: none; /* Для современных браузеров */
    -webkit-user-select: none; /* Для Safari */
}

.credentials_input_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.credentials_input {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  width: 18vw;
  min-width: 170px;
  height: 50px;
  border: 1px solid #d0d5dd;
  border-radius: 32px;
  padding-left: 14px;
}

.credentials_input:focus{
  border: 1px solid #9747FF;
}

.credentials_input:focus::placeholder{
  color:#40444A;
}

.credentials_input.error {
  border:1px solid #F64C4C; 
}

.input_error_message {
  color: #F64C4C; 
  font-size: 12px; 
  margin-top: 5px; 
  font-family: Montserrat;
font-size: 15px;
font-weight: 400;
line-height: 24px;
text-align: left;

}
